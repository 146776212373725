export const TRANSLATIONS_NL = {
  "occasion": "gelegenheid",
  "You don’t have permissions to take a photo": "Je hebt geen toestemming om een foto te nemen",
  "No internet connection": "Nessuna connessione a Internet",
  "Something went wrong": "Er is iets misgegaan.",
  "No services available": "Geen diensten beschikbaar",
  "Password must be at least 8 characters": "Het wachtwoord moet ten minste 8 tekens lang zijn en ten minste 1 hoofdletter en 1 kleine letter, 1 symbool, 1 cijfer en geen spaties bevatten.",
  "Service registration is closed": "Registratie voor de dienst is gesloten.",
  "New password does not meet security requirements": "Le nouveau mot de passe ne répond pas aux exigences de sécurité",
  "You can't remove this temporal item": "Je kunt dit tijdelijke item niet verwijderen",
  "add occasion": "gelegenheid toevoegen",
  "Occasion": "gelegenheid",
  "Optional": "Optioneel",
  "Flight reopened": "Vuelo reabierto",
  "This field is mandatory": "Dit veld is verplicht",
  "success": "succes",
  "Flight registration is closed": "De vluchtregistratie is gesloten.",
  "validating": "valideren van",
  "reopen flight": "vlucht heropenen",
  "close the flight": "sluit de vlucht",
  "finalization": "afronding",
  "approved": "goedgekeurd",
  "failed": "mislukt",
  "validation": "validatie",
  "Photo registration": "Foto registratie",
  "In order to close this flight you need to provide": "Om deze vlucht af te sluiten dient u verplichte gegevens in te vullen.",
  "Photo types required": "Foto's zijn vereist",
  "Something wrong": "Er klopt iets niet.",
  "or": "of",
  "Receive job vacancies": "Vacatures ontvangen",
  "Job vacancy": "Vacature",
  "Go to flights": "Ga naar vluchten",
  "Page not found": "Pagina niet gevonden",
  "Please click here to proceed to flights list": "Klik hier om door te gaan naar de vluchtenlijst",
  "Your session has expired. Please sign in again.": "Uw sessie is verlopen. Gelieve opnieuw in te loggen.",
  "Timestamps type": "Type tijdstempels",
  "You were signed out automatically": "U werd automatisch afgemeld. Token is verlopen. U kunt zich weer aanmelden met uw inloggegevens",
  "Are you sure you want to sign out?": "Weet u zeker dat u zich wilt afmelden?",
  "is signed in": "is getekend in",
  "Sign in": "Meld je aan.",
  "Registration closed": "Inschrijving gesloten",
  "Close registration": "Sluit de registratie",
  "Dummy Type": "Dummy Type",
  "Aircraft Damage": "Vliegtuig Schade",
  "No types here yet": "Nog geen types hier",
  "Select type": "Kies type",
  "Please briefly describe where you found the item": "Beschrijf kort waar u het voorwerp heeft gevonden. Specificeer kamer of lijn en rij indien beschikbaar",
  "type": "type",
  "Describe location": "Beschrijf locatie",
  "Please pick on scheme": "Kies alsjeblieft een schema",
  "Cones": "Kegels",
  "Chocks": "Smoorspoelen",
  "Cannot set a future time.": "Kan geen toekomstige tijd instellen.",
  "This section is used to deal with visible damage to the airplane": "Deze sectie wordt gebruikt voor het behandelen van zichtbare schade aan het vliegtuig. Documenteer elk zichtbaar probleem voordat u onderhoud uitvoert.",
  "Mark location": "Plaats markeren",
  "The end time must be later than the start time.": "De eindtijd moet later zijn dan de begintijd.",
  "Future time is not allowed": "Toekomstige tijd is niet toegestaan",
  "No timestamps configured for this flight type": "Geen tijdstempels geconfigureerd voor dit vluchttypes",
  "Set time": "Stel tijd in",
  "Confirm remove?": "Bevestig verwijderen?",
  "Change": "Verander",
  "Change password?": "Wachtwoord veranderen?",
  "My profile": "Mijn profiel",
  "Destination": "Bestemmings",
  "Origin": "Oorsprong",
  "filter group not found": "filtergroep niet gevonden",
  "Date should not be before start date": "De datum mag niet vóór de begindatum liggen",
  "Could not save data, please try again later": "Kon geen gegevens opslaan, probeer het later nog eens",
  "The current password you've entered is incorrect": "Het huidige wachtwoord dat u heeft ingevoerd is onjuist. Controleer het nogmaals en probeer het opnieuw.",
  "no user data": "geen gebruikersgegevens",
  "Helpdesk": "Helpdesk",
  "SENT": "SENT",
  "DENIED": "DENIED",
  "DECLINED": "VERWIJDERD",
  "CONFIRMED": "BEVESTIGD",
  "ACCEPTED": "AANVAARD",
  "Set to now": "Ingesteld op nu",
  "Set": "Set",
  "Write your comment here, then press the green check mark": "Schrijf hier uw commentaar, druk dan op het groene vinkje",
  "write new comment": "nieuwe opmerking schrijven",
  "Short remark": "Korte opmerking",
  "Filter group has not been updated": "Filtergroep is niet bijgewerkt",
  "Save service registrations problem": "Probleem met dienstregistraties opslaan",
  "and/or": "en/of",
  "Input a brief description here": "Geef hier een korte beschrijving",
  "Right Tail Wing": "Rechter staartvleugel",
  "Left Tail Wing": "Linker staartvleugel",
  "Tail": "Staart",
  "Body": "Lichaam",
  "Neck": "Hals",
  "Nose": "Neus",
  "Right Engine": "Juiste motor",
  "Left Engine": "Linker motor",
  "Right Wing": "Rechts",
  "Left Wing": "Linkervleugel",
  "next": "volgende",
  "Lost item": "Verloren voorwerp",
  "Damage": "Schade",
  "Filter group": "Filter groep",
  "Message archived": "Bericht gearchiveerd",
  "Archived": "Gearchiveerd",
  "Message unarchived": "Bericht niet gearchiveerd",
  "No": "Geen",
  "Yes": "Ja",
  "to": "naar",
  "Date range": "Datumbereik",
  "Telex messages": "Telex berichten",
  "No flight details information": "Geen informatie over de vlucht",
  "No messages yet": "Nog geen berichten",
  "messages yet": "berichten nog",
  "Flight not found": "Vlucht niet gevonden",
  "from": "van",
  "From": "Van",
  "Until": "Tot",
  "Search": "Zoeken op",
  "registration complete": "registratie compleet",
  "Flight number/registration": "Vluchtnummer/registratie",
  "Attachment": "Bijlage",
  "Airline": "Luchtvaartmaatschappij",
  "Username": "Gebruikersnaam",
  "Password": "Wachtwoord",
  "Log in": "Lees en teken",
  "Open file": "Open bestand",
  "Unavailable": "Niet beschikbaar",
  "Read & Sign": "Lees en teken",
  "Available": "Beschikbaar",
  "Sign for understanding": "Teken voor begrip",
  "You can check your APPI code on Sunrise portal.": "Je kunt je APPI code controleren op Sunrise portal.",
  "We strongly recommend saving it in your smartphone phonebook": "Wij raden u ten zeerste aan de code op te slaan in uw telefoonboek van uw smartphone, zodat u de APPI-code altijd bij de hand hebt. Om de code op te slaan, ga je naar je Sunrise profiel en scan je de QR code met je smartphone.",
  "The APPI code is required when resetting your Aviapartner": "De APPI code is nodig bij het resetten van uw Aviapartner account wachtwoord in Sunrise of via de Aviapartner helpdesk. U kunt uw APPI code altijd terugvinden in uw profiel in Sunrise.",
  "Extra shift request confirmed": "Extra dienst verzoek bevestigd",
  "This request is not valid anymore": "Dit verzoek is niet meer geldig",
  "This message is marked as urgent": "Dit bericht is gemarkeerd als dringend",
  "A new password should be different than the current one": "Een nieuw wachtwoord moet anders zijn dan het huidige",
  "Your password must contain at least eight characters": "La tua password deve contenere almeno otto caratteri, almeno un numero e lettere sia minuscole che maiuscole.",
  "Your password has expired. Please compose a new password and log in again.": "Uw wachtwoord is verlopen. Gelieve een nieuw paswoord samen te stellen en opnieuw in te loggen.",
  "Your password and confirmation password do not match": "Uw wachtwoord en bevestigingswachtwoord komen niet overeen.",
  "The username or password you've entered is incorrect": "De gebruikersnaam of het wachtwoord dat u hebt ingevoerd is onjuist. Controleer het nogmaals en probeer het opnieuw. ",
  "Log out": "Log uit.",
  "Change password": "Wijzig wachtwoord",
  "Confirm new password": "Bevestig nieuw wachtwoord",
  "New password": "Nieuw wachtwoord",
  "Current password": "Huidig wachtwoord",
  "is logged in": "is ingelogd",
  "Proceed to login": "Ga verder met inloggen",
  "Your password has been changed successfully!": "La tua password è stata cambiata con successo!",
  "Pick if its a damaged part or lost item": "Kies of het een beschadigd onderdeel of verloren voorwerp is.",
  "Pin on schema": "Speld op schema",
  "Specify where did you found the item": "Geef aan waar u het voorwerp hebt gevonden",
  "Include nearby area": "Omvat nabijgelegen gebied",
  "Add other location": "Andere locatie toevoegen",
  "Add row": "Rij toevoegen",
  "Add line": "Regel toevoegen",
  "Skip this step": "Sla deze stap over",
  "Close": "Sluiten",
  "Add description": "Beschrijving toevoegen",
  "Add location where item was found": "Locatie toevoegen waar item is gevonden",
  "Add storage location": "Opslaglocatie toevoegen",
  "Briefly describe where the item will be stored for picking up.": "Beschrijf kort waar het item wordt opgeslagen om te worden opgehaald.",
  "Take a photo": "Neem een foto",
  "Photos": "Foto's",
  "area": "gebied",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed": "FirebaseError: Een document kan niet worden geschreven omdat het de maximaal toegestane grootte overschrijdt",
  "Messages": "Berichten",
  "Remove": "Verwijder",
  "Are you sure you want to remove photo?": "Weet u zeker dat u de foto wilt verwijderen?",
  "Comment": "Commentaar",
  "Included nearby area": "Inbegrepen nabijgelegen gebied",
  "Tap on area where damaged part is located": "Tik op de plaats waar het beschadigde onderdeel zich bevindt",
  "Conditional services": "Voorwaardelijke diensten",
  "SYSTEM SETTINGS": "SYSTEEMINSTELLINGEN",
  "Flights": "Vluchten",
  "Weather": "Weer",
  "Language": "Taal",
  "Receive Extra shift requests": "Ontvang extra dienstverzoeken",
  "Default station": "Standaard station",
  "Profile data wasn't updated!": "Profielgegevens werden niet geüpdatet!",
  "Station": "Station",
  "Sign out": "Log uit",
  "Cancel": "Annuleren",
  "Stations": "Stations",
  "Profile": "Profiel",
  "Next 5 days": "Volgende 5 dagen",
  "Humidity": "Vochtigheid",
  "Wind": "Wind", "Precipitation": "Neerslag",
  "Pressure": "Druk",
  "Clouds": "Wolken",
  "Visibility": "Zicht",
  "Sunrise": "Zonsopgang",
  "Flight details": "Vluchtgegevens",
  "General info": "Algemene info",
  "Parking": "Parkeren",
  "Runway": "Startbaan",
  "Gate": "Poort",
  "Aircraft type": "Vliegtuigtype",
  "Operation flight number": "Vluchtnummer",
  "Seats": "Zitplaatsen",
  "Seat": "Seat",
  "Flight type": "Vluchttype",
  "Registration": "Registratie",
  "Call sign": "Roepnaam",
  "Crew signature": "HANDTEKENING CREW",
  "Extra services": "Extra diensten",
  "extra service": "Extra service",
  "Comments": "Commentaar",
  "Service registration": "Serviceregistratie",
  "Message type": "BERICHTINGSTYPE",
  "All": "Allemaal",
  "APPLY": "TOEPASSEN",
  "Filter": "Filter",
  "Signed": "Ondertekend",
  "Internal communication": "Interne communicatie",
  "Extra shift request": "Extra ploegendienst verzoek",
  "Extra Shift Request": "Extra ploegendienst verzoek",
  "Please, sign below": "Ondertekenen a.u.b",
  "SAVE": "Save",
  "save": "save",
  "CLEAR": "Overzichtelijk",
  "Service info saved": "Service info opgeslagen",
  "Time registration": "Tijdregistratie",
  "No instructions found": "Geen instructies gevonden",
  "Instructions": "Instructies",
  "NOW": "NU",
  "Sunset": "Zonsondergang",
  "The app has been updated": "De app is bijgewerkt",
  "End time should not be before start time": "De eindtijd mag niet voor de begintijd liggen",
  "Start Time": "Begintijd",
  "Start": "Start",
  "End Time": "Eindtijd",
  "End": "Einde",
  "Today": "Vandaag",
  "TODAY": "VANDAAG",
  "Tomorrow": "Morgen",
  "Yesterday": "Gisteren",
  "No photo available": "Geen foto beschikbaar",
  "Show Photo": "Toon Foto",
  "Walkaround Arrival Checklist": "Aankomst walkaround checklist",
  "Inspections": "Inspecties",
  "Walkaround Pre-Departure Checklist": "Pré-vertrek walkaround checklist",
  "Completed": "Voltooid",
  "Not completed": "Niet voltooid",
  "Visual checks must be performed BEFORE GSE positioning": "Visuele controles moeten worden uitgevoerd VOORDAT materiaal wordt geplaatst",
  "Visual checks must be performed AFTER GSE removal": "Visuele controles moeten worden uitgevoerd NA verwijdering van materiaal",
  "Report any irregularities found upon arrival": "Meld eventuele onregelmatigheden die bij aankomst zijn geconstateerd",
  "Report any other irregularities": "Meld eventuele andere onregelmatigheden",
  "If damage is found, mark it on the sketch": "Als er schade wordt gevonden, markeer deze dan op de schets",
  "Checks on easily visible parts of the aircraft from the ground": "Controleer gemakkelijk zichtbare delen van het vliegtuig vanaf de grond:",
  "Please make sure you attached photos of irregularities": "Zorg ervoor dat u foto's van onregelmatigheden bijvoegt",
  "Add description if there is a irregularity!": "Voeg de beschrijving toe als er een onregelmatigheid is!",
  "Sign form below to complete it": "Onderteken hieronder om het formulier in te vullen:",
  "This form is signed": "Dit formulier is ondertekend",
  "Reopen": "Heropenen",
  "Add note": "Notitie toevoegen...",
  "Hold Inspection on Arrival": "Hold inspectie aankomst",
  "Hold Inspection on Departure": "Hold inspectie vertrek",
  "Flight information registration": "Registratie van vluchtinformatie",
  "Arrival MVT sent successfully": "Arrival MVT succesvol verzonden",
  "Arrival MVT sending": "Verzenden van Arrival MVT",
  "Arrival MVT not sent yet": "Arrival MVT nog niet verzonden",
  "Cannot send Arrival MVT": "Kan Arrival MVT niet verzenden, Adressen (SITA en/of e-mail) voor deze vlucht zijn niet geconfigureerd in Sunrise",
  "SEND ARRIVAL MVT": "VERZEND ARRIVAL MVT",
  "SEND CORRECTION ARRIVAL MVT": "VERZEND CORRECTIE ARRIVAL MVT",
  "In order to send Arrival MVT you need to provide mandatory details": "Om Arrival MVT te verzenden, moet je verplichte details verstrekken",
  "Send the Arrival MVT message to recipients:": "Verzend het Arrival MVT-bericht naar de ontvangers:",
  "the MVT will be sent": "Na het klikken op 'BEVESTIGEN' wordt de MVT verzonden. Het kan opnieuw worden verzonden als er een wijziging is in ALDT en/of AIBT",
  "the correction MVT will be sent": "Na het klikken op 'BEVESTIGEN' wordt de correctie MVT verzonden. Het kan opnieuw worden verzonden als er een wijziging is in ALDT en/of AIBT",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Ongeldige ALDT/AIBT-waarde of Arrival MVT met dergelijke ALDT- en AIBT-waarden bestaat al",
  "Cannot create MVT message: missing flight information": "Kan geen MVT-bericht maken: ontbrekende vluchtinformatie",
  "MVT History": "MVT Geschiedenis",
  "Timeline": "Tijdlijn",
  "No photos here yet": "Nog geen foto's hier",
  "Safety Ramp Checklist": "Veiligheidsrampchecklist",
  "required to close the flight": "vereist om de vlucht te sluiten",
  "Forms": "Formulieren",
  "Message state": "Berichtstatus",
  "Locked application": "Geblokkeerde applicatie",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "Om 'Agenda' te openen, lees en verwerk recente Read & Sign-berichten",
  "Number of unread R&S:": "Aantal ongelezen R&S:",
  "Go to Unread R&S": "Ga naar Ongelezen R&S",
  "The shift is already confirmed to": "De dienst is al bevestigd aan",
  "employees": "medewerkers",
  "Name Surname": "Naam Achternaam",
  "Start anti-icing": "Start anti-icing",
  "Stop anti-icing": "Stop anti-icing",
  "Deicing Service Registration": "Registratie van ontijzingsservice",
  "Anti-icing": "Anti-icing",
  "Type": "Type",
  "Product type": "Producttype",
  "Product name": "Productnaam",
  "Mix": "Mix",
  "Temperature (C°)": "Temperatuur (C°)",
  "Quantity (liter)": "Hoeveelheid (liter)",
  "Add deicing/anti-icing": "Voeg de-icing/anti-icing toe",
  "Agent’s name:": "Naam van de employee: ",
  "Agent’s signature:": "Handtekening van employee:",
  "Client’s signature:": "Handtekening van de klant:",
  "Load Verification Cross-Check Form": "Laadverificatie Cross-Check Formulier",
  "You are offline": "Je bent offline.",
  "The planning shown below might not be up-to-date": "Het kan zijn dat onderstaande planning niet actueel is.",
  "Read": "Lezen",
  "Unread": "Ongelezen",
  "The elapsed time between": "De verstreken tijd tussen ",
  "and": " en ",
  "exceeds the allowed limit for this flight": " o verschrijdt de toegestane limiet voor deze vlucht.",
  "Cannot be before": "Kan niet eerder zijn ",
  "Cannot be after": "Kan niet na zijn ",
  "time": " tijd.",
  "Required": "Vereist",
  "Set date": "Datum instellen",
  "additional services": "Aanvullende diensten",
  "Day": "Dag",
  "Month": "Maand",
  "Year": "Jaar",
  "Inspection agent’s signature:": "Handtekening van de inspecteur:",
  "Truck driver’s signature:": "Handtekening vrachtwagenchauffeur:",
  "Executor’s signature:": "Handtekening van de executeur:",
  "Executor’s name:": "Naam van de executeur:",
  "Truck Number": "Vrachtwagennummer",
  "Truck Driver": "Vrachtwagenchauffeur",
  "Inspected by": "Geïnspecteerd door",
  "Outside Air Temperature": "Buitenluchttemperatuur (C°)",
  "Is Partial": "Is gedeeltelijk",
};
