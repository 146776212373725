export const TRANSLATIONS_FR = {
  "occasion": "occasion",
  "You don’t have permissions to take a photo": "Vous n'avez pas l'autorisation de prendre une photo.",
  "No internet connection": "Pas de connexion internet",
  "Something went wrong": "Quelque chose a mal tourné",
  "No services available": "Aucun service disponible",
  "Password must be at least 8 characters": "Le mot de passe doit comporter au moins 8 caractères et contenir au moins 1 lettre majuscule et 1 lettre minuscule, 1 symbole, 1 chiffre et aucun espace.",
  "Service registration is closed": "L'inscription au service est terminée.",
  "New password does not meet security requirements": "Le nouveau mot de passe ne répond pas aux exigences de sécurité",
  "You can't remove this temporal item": "Vous ne pouvez pas supprimer cet élément temporel",
  "add occasion": "ajouter une occasion",
  "Occasion": "Occasion",
  "Optional": "Optionnel",
  "Flight reopened": "Vuelo reabierto",
  "This field is mandatory": "Ce champ est obligatoire",
  "success": "succès",
  "Flight registration is closed": "El registro de vuelos está cerrado.",
  "validating": "validation de",
  "reopen flight": "réouverture du vol",
  "close the flight": "fermer le vol",
  "finalization": "finalisation",
  "approved": "approuvé",
  "failed": "échoué",
  "validation": "validación",
  "Photo registration": "Enregistrement des photos",
  "In order to close this flight you need to provide": "Afin de fermer ce vol, vous devez fournir des détails obligatoires.",
  "Photo types required": "Types de photos requis",
  "Something wrong": "Quelque chose ne va pas",
  "or": "ou",
  "Receive job vacancies": "Recevoir les offres d'emploi",
  "Job vacancy": "Offre d'emploi",
  "Go to flights": "Aller aux vols",
  "Page not found": "Page non trouvée",
  "Please click here to proceed to flights list": "Veuillez cliquer ici pour accéder à la liste des vols",
  "Your session has expired. Please sign in again.": "Votre session a expiré. Veuillez vous connecter à nouveau.",
  "Timestamps type": "Type d'horodatage",
  "You were signed out automatically": "Vous avez été déconnecté automatiquement. Le jeton a expiré. Vous pouvez vous reconnecter avec vos informations d'identification.",
  "Are you sure you want to sign out?": "Vous êtes sûr de vouloir vous déconnecter ?",
  "is signed in": "est signé en",
  "Sign in": "Se connecter",
  "Registration closed": "Inscription fermée",
  "Close registration": "Fermer l'enregistrement",
  "Dummy Type": "Type de mannequin",
  "Aircraft Damage": "Dommages aux aéronefs",
  "No types here yet": "Aucun type ici pour le moment",
  "Select type": "Sélectionnez le type",
  "Please briefly describe where you found the item": "Veuillez décrire brièvement où vous avez trouvé l'article. Précisez la pièce ou la ligne et la rangée si disponible",
  "type": "type",
  "Describe location": "Décrire l'emplacement",
  "Please pick on scheme": "Veuillez choisir le régime",
  "Cones": "Cônes",
  "Chocks": "Cales",
  "Cannot set a future time.": "Impossible de définir une heure future.",
  "This section is used to deal with visible damage to the airplane": "Cette section est utilisée pour traiter les dommages visibles de l'avion. Veuillez documenter chaque problème visible avant d'effectuer tout service.",
  "Mark location": "IEmplacement de la marque",
  "The end time must be later than the start time.": "L'heure de fin doit être postérieure à l'heure de début.",
  "Future time is not allowed": "Le temps futur n'est pas autorisé",
  "No timestamps configured for this flight type": "Aucun horodatage configuré pour ce type de vol",
  "Set time": "Définir le temps",
  "Confirm remove?": "Confirmer la suppression ?",
  "Change": "Changement",
  "Change password?": "Changer le mot de passe ?",
  "My profile": "Mon profil",
  "Origin": "Origine",
  "filter group not found": "groupe de filtres non trouvé",
  "Date should not be before start date": "La date ne doit pas être antérieure à la date de début",
  "Could not save data, please try again later": "Impossible de sauvegarder les données, veuillez réessayer plus tard",
  "The current password you've entered is incorrect": "Le mot de passe actuel que vous avez saisi est incorrect. Veuillez le vérifier et réessayer.",
  "no user data": "aucune donnée utilisateur",
  "Helpdesk": "Service d'assistance",
  "SENT": "ENVOYÉ",
  "DENIED": "REFUSÉ",
  "DECLINED": "DÉCLINÉ",
  "CONFIRMED": "CONFIRMÉ",
  "ACCEPTED": "ACCEPTÉ",
  "Set": "Définir",
  "Set to now": "Réglé sur maintenant",
  "Write your comment here, then press the green check mark": "Écrivez votre commentaire ici, puis cochez la case verte.",
  "write new comment": "écrire un nouveau commentaire",
  "Short remark": "Brève remarque",
  "Filter group has not been updated": "Le groupe de filtres n'a pas été mis à jour",
  "Save service registrations problem": "Sauvegarder le problème des enregistrements de service",
  "and/or": "et/ou",
  "Input a brief description here": "Saisissez une brève description ici",
  "Right Tail Wing": "Aile arrière droite",
  "Left Tail Wing": "Aile arrière gauche",
  "Tail": "Queue",
  "Body": "Corps",
  "Neck": "Cou",
  "Nose": "Nez",
  "Right Engine": "Moteur droit",
  "Left Engine": "Moteur gauche",
  "Right Wing": "L'aile droite",
  "Left Wing": "Aile gauche",
  "next": "suivant",
  "Lost item": "Objet perdu",
  "Damage": "Dégâts",
  "Filter group": "Groupe de filtres",
  "Archived": "Archivél",
  "Message archived": "Message archivél",
  "Message unarchived": "Message non archivé",
  "No": "Non",
  "Yes": "Oui",
  "to": "à",
  "Date range": "Plage de dates",
  "Telex messages": "Messages du télex",
  "No flight details information": "Aucune information sur les détails du vol",
  "No messages yet": "Aucun message pour le moment",
  "messages yet": "messages encore",
  "Flight not found": "Vol non trouvé",
  "from": "de",
  "From": "De",
  "Until": "Jusqu'à",
  "Search": "Recherche",
  "registration complete": "inscription terminée",
  "Flight number/registration": "Numéro de vol/enregistrement",
  "Attachment": "Pièce jointe",
  "Airline": "Compagnie aérienne",
  "Username": "Nom d'utilisateur",
  "Password": "Mot de passe",
  "Log in": "Connectez-vous",
  "Open file": "Ouvrir le dossier",
  "Unavailable": "Indisponible",
  "Read & Sign": "Lire et signer",
  "Available": "Disponible sur",
  "Sign for understanding": "Signez pour comprendre",
  "You can check your APPI code on Sunrise portal.": "Vous pouvez vérifier votre code APPI sur le portail Sunrise.",
  "We strongly recommend saving it in your smartphone phonebook": "Nous vous recommandons vivement de l'enregistrer dans le répertoire de votre smartphone afin d'avoir toujours le code APPI à portée de main. Pour enregistrer le code, rendez-vous sur votre profil Sunrise et scannez le code QR avec votre smartphone.",
  "The APPI code is required when resetting your Aviapartner": "Le code APPI est nécessaire pour réinitialiser le mot de passe de votre compte Aviapartner dans Sunrise ou par le biais du service d'assistance Aviapartner. Vous pouvez toujours trouver votre code APPI dans votre profil dans Sunrise.",
  "Extra shift request confirmed": "Demande de poste supplémentaire confirmée",
  "This request is not valid anymore": "Cette demande n'est plus valable",
  "This message is marked as urgent": "Ce message est marqué comme urgent",
  "A new password should be different than the current one": "Un nouveau mot de passe doit être différent du mot de passe actuel.",
  "Your password has expired. Please compose a new password and log in again.": "Votre mot de passe a expiré. Veuillez composer un nouveau mot de passe et vous connecter à nouveau.",
  "Your password and confirmation password do not match": "Votre mot de passe et le mot de passe de confirmation ne correspondent pas.",
  "Your password must contain at least eight characters": "Votre mot de passe doit contenir au moins huit caractères, au moins un chiffre et des lettres minuscules et majuscules. ",
  "The username or password you've entered is incorrect": "Le nom d'utilisateur ou le mot de passe que vous avez saisi est incorrect. Veuillez vérifier à nouveau et réessayer.",
  "Log out": "Déconnexion",
  "Change password": "Modifier le mot de passe",
  "Confirm new password": "Confirmez le mot de passe",
  "New password": "Nouveau mot de passe",
  "Current password": "Mot de passe actuel",
  "is logged in": "est connecté",
  "Proceed to login": "Procéder à la connexion",
  "Your password has been changed successfully!": "Votre mot de passe a été changé avec succès !",
  "Pick if its a damaged part or lost item": "Choisissez s'il s'agit d'une pièce endommagée ou d'un objet perdu",
  "Pin on schema": "Épingle sur le schéma",
  "Specify where did you found the item": "Précisez où vous avez trouvé l'article",
  "Include nearby area": "Inclure la zone proche",
  "Add other location": "Ajouter un autre lieu",
  "Add row": "Ajouter une ligne",
  "Add line": "Ajouter une ligne",
  "Skip this step": "Sauter cette étape",
  "Photos": "Photos",
  "Take a photo": "Prenez une photo",
  "Add description": "Ajouter une description",
  "Add location where item was found": "Ajouter le lieu où l'objet a été trouvé",
  "Add storage location": "Ajouter le lieu de stockage",
  "Briefly describe where the item will be stored for picking up.": "Décrivez brièvement l'endroit où l'objet sera stocké avant d'être récupéré.",
  "Close": "Fermer",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed": "FirebaseError : Un document ne peut être écrit car il dépasse la taille maximale autorisée",
  "area": "zone",
  "Messages": "Messages",
  "Remove": "Retirer",
  "Are you sure you want to remove photo?": "Vous êtes sûr de vouloir supprimer la photo ?",
  "Comment": "Commentaire",
  "Included nearby area": "Inclus dans la zone proche",
  "Tap on area where damaged part is located": "Tapez sur la zone où se trouve la partie endommagée",
  "Conditional services": "Services conditionnels",
  "SYSTEM SETTINGS": "PARAMÈTRES DU SYSTÈME",
  "Flights": "Vols",
  "Weather": "Météo",
  "Language": "Langue",
  "Receive Extra shift requests": "Recevoir des demandes de quart supplémentaire",
  "Default station": "Station par défaut",
  "Profile data wasn't updated!": "Les données du profil n'ont pas été mises à jour ! ",
  "Station": "Station",
  "Sign out": "Déconnexion",
  "Cancel": "Annuler",
  "Stations": "Stations",
  "Profile": "Profil",
  "Next 5 days": "5 jours suivants",
  "Today": "Aujourd'hui",
  "TODAY": "AUJOURD'HUI",
  "Humidity": "Humidité",
  "Wind": "Vent",
  "Precipitation": "Précipitations",
  "Pressure": "Pression",
  "Clouds": "Nuages",
  "Visibility": "Visibilité",
  "Sunrise": "Lever de soleil",
  "Flight details": "Détails du vol",
  "General info": "Informations générales",
  "Parking": "Parking",
  "Runway": "Piste",
  "Gate": "Porte",
  "Aircraft type": "Type d'avion",
  "Operation flight number": "Numéro de vol d'exploitation",
  "Seats": "Sièges",
  "Seat": "Siège",
  "Flight type": "Type de vol",
  "Registration": "Enregistrement",
  "Call sign": "Indicatif d'appel",
  "Crew signature": "SIGNATURE CREW",
  "Extra services": "Services supplémentaires",
  "extra service": "Service supplémentaire",
  "Comments": "Commentaires",
  "Service registration": "Enregistrement du service",
  "Message type": "TYPE DE MESSAGE",
  "All": "Tous",
  "APPLY": "DEMANDEZ",
  "Filter": "Filtrez",
  "Signed": "Signé",
  "Internal communication": "Communication interne",
  "Extra shift request": "Demande de poste supplémentaire",
  "Extra Shift Request": "Demande de poste supplémentaire",
  "Please, sign below": "Veuillez signer ci-dessous",
  "SAVE": "Enregistrer",
  "save": "enregistrer",
  "CLEAR": "EFFACER",
  "Service info saved": "Informations sur le service sauvegardées",
  "Time registration": "Enregistrement du temps",
  "No instructions found": "Aucune instruction trouvée",
  "Instructions": "Instructions",
  "NOW": "MAINTENANT",
  "Sunset": "Coucher de soleil",
  "The app has been updated": "L'application a été mise à jour",
  "End time should not be before start time": "L'heure de fin ne doit pas être antérieure à l'heure de début",
  "Start Time": "Heure de début",
  "Start": "Début",
  "End Time": "Heure de fin",
  "End": "Fin",
  "Tomorrow": "Demain",
  "Yesterday": "Hier",
  "No photo available": "Aucune photo disponible",
  "Show Photo": "Afficher la photo",
  "Walkaround Arrival Checklist": "Liste de contrôle Tour avion arrivée",
  "Inspections": "Inspections",
  "Walkaround Pre-Departure Checklist": "Liste de contrôle Tour avion avant départ",
  "Completed": "Effectué",
  "Not completed": "Non effectué",
  "Visual checks must be performed BEFORE GSE positioning": "Les vérifications visuelles doivent être effectuées AVANT la mise en place du matériel",
  "Visual checks must be performed AFTER GSE removal": "Les vérifications visuelles doivent être effectuées APRES le retrait du matériel",
  "Report any irregularities found upon arrival": "Signalez toute anomalie trouvée à l'arrivée",
  "Report any other irregularities": "Signalez toute autre anomalie",
  "If damage is found, mark it on the sketch": "Si des dommages sont trouvés, marquez-les sur le croquis",
  "Checks on easily visible parts of the aircraft from the ground": "Vérifiez les parties facilement visibles de l'avion à partir du sol. Aucun dommage évident ni anomalie sur:",
  "Please make sure you attached photos of irregularities": "Vérifiez que vous avez joint des photos des anomalies",
  "Add description if there is a irregularity!": "Ajoutez une description s'il y a une anomalie!",
  "Sign form below to complete it": "Signez le formulaire ci-dessous pour le terminer:",
  "This form is signed": "Ce formulaire est signé",
  "Reopen": "Rouvrir",
  "Add note": "Ajouter une note...",
  "Hold Inspection on Arrival": "Inspection des soutes arrivée",
  "Hold Inspection on Departure": "Inspection des soutes départ",
  "Flight information registration": "Enregistrement des informations de vol",
  "Arrival MVT sent successfully": "Arrivée MVT envoyée avec succès",
  "Arrival MVT sending": "Envoi du MVT d'arrivée",
  "Arrival MVT not sent yet": "Le MVT d'arrivée n'a pas encore été envoyé",
  "Cannot send Arrival MVT": "Impossible d'envoyer le MVT d'arrivée. Les adresses (SITA et/ou e-mail) pour ce vol ne sont pas configurées dans Sunrise.",
  "SEND ARRIVAL MVT": "ENVOYER LE MVT D'ARRIVÉE",
  "SEND CORRECTION ARRIVAL MVT": "ENVOYER LA CORRECTION DU MVT D'ARRIVÉE",
  "In order to send Arrival MVT you need to provide mandatory details": "Pour envoyer le MVT d'arrivée, vous devez saisir les informations obligatoires.",
  "Send the Arrival MVT message to recipients:": "Envoyez le message MVT d'arrivée aux destinataires:",
  "the MVT will be sent": "Après avoir cliqué sur 'CONFIRMER', le MVT sera envoyé. Il peut être renvoyé en cas de modification de l'ALDT ou/et de l'AIBT.",
  "the correction MVT will be sent": "Après avoir cliqué sur 'CONFIRMER', le message de correction du MVT sera envoyé. Il peut être renvoyé en cas de modification de l'ALDT ou/et de l'AIBT.",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Valeur ALDT/AIBT non valide ou MVT d'arrivée avec de telles valeurs ALDT et AIBT existe déjà",
  "Cannot create MVT message: missing flight information": "Impossible de créer un message MVT: informations de vol manquantes",
  "MVT History": "Historique MVT",
  "Timeline": "Chronologie",
  "No photos here yet": "Aucune photo ici pour le moment",
  "Safety Ramp Checklist": "Liste de contrôle sécurité piste",
  "required to close the flight": "La fermeture du vol est demandée",
  "Forms": "Formulaires",
  "Message state": "État du message",
  "Locked application": "Application verrouillée",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "Pour ouvrir le 'Calendrier', lisez et validez les récentes notes de services.",
  "Number of unread R&S:": "Nombre de R&S non lus:",
  "Go to Unread R&S": "Accéder aux R&S non lus",
  "The shift is already confirmed to": "La vacation est déjà confirmée à",
  "employees": "employés",
  "Name Surname": "Nom Prénom",
  "Start anti-icing": "Début antigivrage",
  "Stop anti-icing": "Fin antigivrage",
  "Deicing Service Registration": "Inscription au service de dégivrage",
  "Anti-icing": "Antigivrage",
  "Type": "Type",
  "Product type": "Type de produit",
  "Product name": "Nom du produit",
  "Mix": "Mélange",
  "Temperature (C°)": "Température (C°)",
  "Quantity (liter)": "Quantité (litre)",
  "Add deicing/anti-icing": "Ajouter dégivrage/antigivrage",
  "Agent’s name:": "Nom de l'agent:",
  "Agent’s signature:": "Signature de l'agent:",
  "Client’s signature:": "Signature du client:",
  "Load Verification Cross-Check Form": "Formulaire de réconciliation du chargement",
  "You are offline": "Vous êtes hors ligne.",
  "The planning shown below might not be up-to-date": "Le planning ci-dessous n'est peut-être pas à jour.",
  "Read": "Lire",
  "Unread": "Non lu",
  "The elapsed time between": "Le temps écoulé entre ",
  "and": " et ",
  "exceeds the allowed limit for this flight": " dépasse la limite autorisée pour ce vol.",
  "Cannot be before": "Ça ne peut pas être avant ",
  "Cannot be after": "Je ne peux pas être après ",
  "time": " temps.",
  "Required": "Requis",
  "Set date": "Fixer la date",
  "additional services": "Prestations supplémentaires",
  "Day": "Jour",
  "Month": "Mois",
  "Year": "Année",
  "Inspection agent’s signature:": "Signature de l'agent d'inspection:",
  "Truck driver’s signature:": "Signature du chauffeur du camion:",
  "Executor’s signature:": "Signature de l'exécuteur testamentaire:",
  "Executor’s name:": "Nom de l’exécuteur testamentaire:",
  "Truck Number": "Numéro de camion",
  "Truck Driver": "Chauffeur de camion",
  "Inspected by": "Inspecté par",
  "Outside Air Temperature": "Température de l'air extérieur (C°)",
  "Is Partial": "Est partiel",
};
