import { initializeApp } from 'firebase/app';
import { initializeFirestore, persistentLocalCache } from 'firebase/firestore';
import { getMessaging, getToken, isSupported as isMessagingSupported } from 'firebase/messaging';
import {
  getAuth,
  setPersistence,
  signOut,
  signInWithCustomToken,
  browserSessionPersistence,
  inMemoryPersistence,
} from 'firebase/auth';

import { environment as env } from 'environments';
import { axiosInstance } from 'utils/API';
import LocalStorageService from 'services/LocalStorageService';
import moment from 'moment';
import { isNotActualCache } from 'utils/helpers.js';
import { DATE_TIME_FORMAT } from 'utils/DateFormats';
import { Platform } from "../utils/platform";

const LAST_FIREBASE_AUTH_DATE = 'lastFirebaseAuthDate';
const FIREBASE_CUSTOM_TOKEN = 'firebaseCustomToken';

// Initialize Firebase app
const firebase = initializeApp(env.firebase);

// Initialize Firestore
const firestoreState = initializeFirestore(firebase, {
  cache: persistentLocalCache(), // Enables persistent caching
});

// Set Auth persistence
const auth = getAuth(firebase);
setPersistence(
  auth,
  process.env.NODE_ENV === 'test' ? inMemoryPersistence : browserSessionPersistence)
  .catch(error => {
    console.log('setPersistence error', error);
  });

// Firebase sign out
export const firebaseSignOut = () => {
  signOut(auth)
  .then(() => {})
  .catch(err => console.log('Firebase logout error', err));
};

// Sign in with custom token
const signInWithCustomTokenHandler = customToken => {
  if (customToken) {
    signInWithCustomToken(auth, customToken)
      .then(res => {
        localStorage.setItem(
          LAST_FIREBASE_AUTH_DATE,
          moment().format(DATE_TIME_FORMAT)
        );
      })
      .catch(err => {
        console.log('firebase.auth err', err);
      });
  }
};

// Firebase authenticate
export const firebaseAuthenticate = () => {
  axiosInstance
    .get('/firebase-token')
    .then(res => {
      const { customToken } = res && res.data;
      if (customToken) {
        localStorage.setItem(
          FIREBASE_CUSTOM_TOKEN,
          JSON.stringify(customToken)
        );
        signInWithCustomTokenHandler(customToken);
      }
    })
    .catch(err => {
      const customToken =
        JSON.parse(localStorage.getItem(FIREBASE_CUSTOM_TOKEN)) || null;
      if (customToken) {
        signInWithCustomTokenHandler(customToken);
      } else {
        console.log('firebase-token error', err);
      }
    });
};

// Delete Web Push subscription
export const deleteWebPushSubscription = (currentToken) => {
  if (!currentToken) {
    return Promise.resolve();
  }

  return axiosInstance
    .delete('/messages/subscriptions', { data: { token: currentToken } });
};

export const initMessaging = async () => {
  const platform = Platform.getPlatform();
  const currentToken = LocalStorageService.getWebPushSubscription();

  if (!platform.isMessagingSupported) {
    console.warn('Messaging not supported on this platform.');
    return;
  }

  if (!await isMessagingSupported()) {
    console.warn('Firebase messaging is not supported.');
    return;
  }

  try {
    const messaging = getMessaging(firebase);

    // Get service worker registrations
    const registrations = await navigator.serviceWorker.getRegistrations();


    // Check if firebase-messaging-sw.js is already registered
    let firebaseSW = registrations.find(reg => reg.active?.scriptURL.includes('firebase-messaging-sw.js'));

    // If no service worker is registered, register firebase-messaging-sw.js
    if (!firebaseSW || !firebaseSW.active || !firebaseSW.active.scriptURL.includes('firebase-messaging-sw.js')) {
      firebaseSW = await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' });
    }

    // Get FCM token using the registered service worker
    const token = await getToken(messaging, { serviceWorkerRegistration: firebaseSW });

    if (token && token !== currentToken) {
      await deleteWebPushSubscription(currentToken).catch(e => console.error(e));
      axiosInstance
        .post('/messages/subscriptions', { token })
        .then(res => LocalStorageService.setWebPushSubscription(res.data?.token))
        .catch(e => console.error(e));
    }
  } catch (err) {
    console.error('Error while retrieving token:', err);
  }
};


// Check and re-fetch Firebase Auth
export const checkAndReFetchFirebaseAuth = () => {
  const lastCacheDate = localStorage.getItem(LAST_FIREBASE_AUTH_DATE) || null;
  if (isNotActualCache(lastCacheDate, 59)) {
    firebaseSignOut();
    firebaseAuthenticate();
  }
};

export const firestore = firestoreState;
export default firebase;
